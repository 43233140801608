import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../../../Wow";
import { productByPrawns, listOfCategory } from "../../../../constants";
import { usePathLang } from "../../../../hooks/usePathLang";
import { langRedirect } from "../../../../helpers/redirect";

const slug = listOfCategory[3].slug

const Lists = () => {
  const { t } = useTranslation();
  const { detectLang } = usePathLang();

  const redirectWithLang = (link: string) => langRedirect(detectLang, link)


  const renderLists = () => {
    return productByPrawns.map((val) => {
      const productName = t(`productsLists.prawns.${val.data.link}.title`);

      return (
        <div className="col-lg-4 col-md-6 pb-1" key={val.data.link}>
        <Link
          to={redirectWithLang(`/product/${slug}/${val.data.link}`)}
          className="cat-item d-flex flex-column border mb-4"
          style={{ padding: "30px" }}
        >
          <div
            className="cat-img position-relative overflow-hidden mb-3"
          >
            <img
              className="img-fluid Sirv"
              src={val.src}
              alt={val.alt}
            />
          </div>
          <h5 className="font-weight-semi-bold m-0">{productName}</h5>
        </Link>
      </div>
      );
    });
  };

  return (
    <ComponentWow>
      <div className="container-fluid pt-5">
        <div className="row px-xl-5 pb-3">{renderLists()}</div>
      </div>
    </ComponentWow>
  );
};

export default Lists;
